













































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'

const ConnectionType = {
  Off: 0,
  Optional: 1,
  Required: 2,
}

class ConnectedService {
  id: number
  serviceId: number
  serviceName: string
  connectionType: number
}

class Service {
  id: number
  name: string
  code: string
  duration: number
  type: string
  mode: string
  seasonMode: string
  visibility: string
  description: string
  categoryId: number
  productTypeId: number
  profitStaticPrice: string
  costPrice: string
  primaryServices: Array<ConnectedService>
  addToOrder: boolean
  disableNotification: boolean
  sortOrder: number

  constructor(allPrimaryServices = null, data = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id || null
    this.name = data.name || ''
    this.code = data.code || ''
    this.duration = data.duration || '0'
    this.type = data.type || ''
    this.mode = data.mode || 'Standard'
    this.seasonMode = data.seasonMode || 'ByDate'
    this.visibility = data.visibility || ''
    this.description = data.description || ''
    this.categoryId = data.categoryId || null
    this.productTypeId = data.productTypeId || null
    this.profitStaticPrice = data.profitStaticPrice || '0'
    this.costPrice = data.costPrice || '0'
    this.addToOrder = data.addToOrder || false
    this.disableNotification = data.disableNotification || false
    this.sortOrder = data.sortOrder ? (data.sortOrder as number) : 0
    this.primaryServices = this.fromBackendPrimaryServices(allPrimaryServices, data.primaryServices)
  }

  public toBackendPrimaryServices() {
    const result = []
    for (let i = 0; i < this.primaryServices.length; i++) {
      const s = this.primaryServices[i]
      if (s.connectionType === ConnectionType.Off) {
        continue
      }
      result.push({
        id: s.id,
        primaryServiceId: s.serviceId,
        isRequired: s.connectionType === ConnectionType.Required,
      })
    }
    return result
  }

  private fromBackendPrimaryServices(allPrimaryServices, connectedPrimaryServices) {
    if (!allPrimaryServices) {
      return []
    }
    if (!connectedPrimaryServices) {
      connectedPrimaryServices = []
    }
    const result = []
    for (let i = 0; i < allPrimaryServices.length; i++) {
      const s = {
        id: null,
        serviceId: allPrimaryServices[i].id,
        serviceName: allPrimaryServices[i].name,
        connectionType: ConnectionType.Off,
      }
      connectedPrimaryServices.forEach((cps) => {
        if (cps.primaryServiceId === s.serviceId) {
          s.id = cps.id
          s.connectionType = cps.isRequired ? ConnectionType.Required : ConnectionType.Optional
        }
      })
      result.push(s)
    }
    return result
  }
}

class TyreHotelProduct {
  id: number
  name: string
  isEnabled: boolean

  public constructor(data: Record<string, unknown>) {
    if (!data) {
      data = {}
    }
    this.id = (data.id as number) || null
    this.name = (data.name as string) || ''
    this.isEnabled = false
  }
}

@Component({})
export default class List extends Vue {
  $refs: Vue['$refs'] & {
    form: {
      validate: any
    }
  }

  ConnectionType: any = ConnectionType

  loading = true
  data: Service = new Service()
  primaryServices = []
  categories = []
  productTypes = []
  modes = [
    { id: 'Standard', name: 'Standard' },
    { id: 'TyreHotelAny', name: 'TyreHotelAny' },
    { id: 'TyreHotelSome', name: 'TyreHotelSome' },
    { id: 'TyreHotelTermination', name: 'TyreHotelTermination' },
    { id: 'NotTyreHotel', name: 'NotTyreHotel' },
  ]
  seasonModes = [
    { id: 'ByDate', name: 'By date (book current season)' },
    { id: 'ByStock', name: 'By stock (book what is in stock)' },
  ]
  visibilityOptions = [
    'Public',
    'Private',
    'PublicIfTyreHotel',
    'PublicIfNotTyreHotel',
  ]

  tyreHotelProducts: Array<TyreHotelProduct> = []

  rules = {
    name: [],
    duration: [],
  }

  mounted() {
    this.rules.name = [(v) => !!v || this.$t('c:validation:This field is required')]

    if (this.isAddon) {
      this.rules.duration = [
        (v) => !v || /^\d+$/.test(v) || this.$t('c:booking-service:Duration must be 0 or larger'),
      ]
      this.loadPrimaryServices(() => {
        this.load()
      })
    } else {
      this.rules.duration = [
        (v) =>
          (!!v && v > 0) ||
          this.$t('c:booking-service:Duration is required and must be larger then 0 for primary services'),
      ]
      this.loadTyreHotelProducts(() => {
        this.load()
      })
    }
  }

  load() {
    this.loadServiceCategories(() => {
      this.loadProductTypes(() => {
        this.loadService()
      })
    })
  }

  loadTyreHotelProducts(callback) {
    this.$axios
      .get('/v4/site/products/tyre-hotel?perPage=1000')
      .then((response) => {
        this.tyreHotelProducts = response.data.data.map((p) => {
          return new TyreHotelProduct(p)
        })
        callback()
      })
      .catch((err) => {
        this.loading = false
        vxm.alert.onAxiosError(err)
      })
  }

  loadPrimaryServices(callback) {
    const url = this.baseUrl + '?perPage=1000&searchToFilters=1&search=' + encodeURIComponent('type:=1')
    this.$axios
      .get(url, this.data)
      .then((response) => {
        this.primaryServices = response.data.data
        callback()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed loading (primary) services')
      })
  }

  loadServiceCategories(callback) {
    this.$axios
      .get('/v4/site/products/categories')
      .then((response) => {
        this.categories = response.data.data
        callback()
      })
      .catch((err) => {
        this.loading = false
        vxm.alert.onAxiosError(err, 'Failed loading service categories')
      })
  }

  loadProductTypes(callback) {
    this.$axios
      .get('/v4/site/products/types')
      .then((response) => {
        this.productTypes = response.data.data
        callback()
      })
      .catch((err) => {
        this.loading = false
        vxm.alert.onAxiosError(err, 'Failed loading product types')
      })
  }

  loadService() {
    if (this.isNew) {
      this.data = new Service(this.primaryServices, {})
      this.data.type = this.isAddon ? 'AddonForAll' : 'Primary'
      this.data.visibility = 'Public'
    } else {
      this.loading = true
      this.$axios
        .get(this.url)
        .then((response) => {
          this.data = new Service(this.primaryServices, response.data.data)
          if (this.data.mode === 'TyreHotelSome') {
            this.tyreHotelProducts.forEach((p) => {
              if (response.data.data.tyreHotelProductIds.indexOf(p.id) !== -1) {
                p.isEnabled = true
              }
            })
          }
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
          vxm.alert.onAxiosError(err, 'Failed loading service')
        })
    }
  }

  save() {
    if (this.$refs.form.validate()) {
      const data: any = Object.assign({}, this.data)
      data.primaryServices = this.data.toBackendPrimaryServices()
      data.tyreHotelProductIds = this.enabledTyreHotelProductIds
      if (this.isNew) {
        this.$axios
          .post(this.url, data)
          .then((response) => {
            this.data = response.data.data
            this.$router.back()
          })
          .catch((err) => {
            vxm.alert.onAxiosError(err)
          })
      } else {
        this.$axios
          .put(this.url, data)
          .then((response) => {
            this.data = response.data.data
            this.$router.back()
          })
          .catch((err) => {
            vxm.alert.onAxiosError(err)
          })
      }
    }
  }

  cancel() {
    this.$router.back()
  }

  get enabledTyreHotelProductIds() {
    const result = []
    if (this.data.mode === 'TyreHotelSome') {
      for (let i = 0; i < this.tyreHotelProducts.length; i++) {
        if (this.tyreHotelProducts[i].isEnabled) {
          result.push(this.tyreHotelProducts[i].id)
        }
      }
    }
    return result
  }

  get isAddon() {
    return !!(this.$route.meta && this.$route.meta.isAddonService)
  }

  get isNew() {
    return !(this.$route.params.id && parseInt(this.$route.params.id) !== 0)
  }

  get baseUrl() {
    return '/v4/site/calendars/' + this.$route.params.calendarId + '/services'
  }

  get url() {
    const url = this.baseUrl
    return this.isNew ? url : url + '/' + this.$route.params.id
  }
}
